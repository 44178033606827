import React from "react";
import { Container, Row } from "react-bootstrap";

import Particle from "../components/Particle";
import CarouselComponent from "../components/Home/CarouselComponent";

import Projects from "../components/Projects/Projects";
import Counter from "../components/Home/Counter";

function Home() {
  return (
    <section fluid className="home-all">
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <CarouselComponent indicators={false} />
        </Container>
      </Container>
      <Projects />
      <Container fluid className="counter-section" id="home">
        <Container className="counter-content">
          <Counter downloads="2000" ratings="3.8/1200" happy_clients="500" />
        </Container>
      </Container>
    </section>
  );
}

export default Home;
