import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function NishantInfo() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        
      </Card.Body>
    </Card>
  );
}

export default NishantInfo;