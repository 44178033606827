import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function ChasInfo() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Chas Inzinna</span>. 
            I am <span className="purple">CEO</span> of <span className="purple">SalonTechnologies, Inc</span>. 
            I founded the company in <span className="purple">2003</span>. I am the Author of the Hair Color Formula Encyclopedia, inventor of the 
            <span className="purple"> PSA: Personal Salon Assistant </span> 
            software with additional patented Hair Color Formulating software and full CRM capabilities. 
            <br />
            <br />
            I am also the owner of the <a href='https://www.sohosalon.net' target="_blank" className="purple">Soho Salon</a> in Pittsford, NY and is a Master Color Artist.
            <br />
            <br />
            <span className="purple">Specialties: </span>
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Marketing.
            </li>
            <li className="about-activity">
              <ImPointRight /> Branding
            </li>
            <li className="about-activity">
              <ImPointRight /> Internet Awareness
            </li>
            <li className="about-activity">
              <ImPointRight /> International Hair Color Artist
            </li>
            <li className="about-activity">
              <ImPointRight /> Author
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default ChasInfo;