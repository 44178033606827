import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../components/Particle";

// components
import ContactInformation from "../components/Contact/ContactInformation";
import GoogleMapComp from "../components/Contact/GoogleMap";

function Contact() {
  return (
    <Container fluid className="contact-section">
      <Particle />
      <Container>
        <Card className="contact-card-view">
          <Row style={{ justifyContent: "center", padding: "10px" }}>
            <Col md={5} className="contact-content">
              <ContactInformation />
            </Col>
            <Col
              md={7}
              style={{
                justifyContent: "center",
                minHeight: 650,
                borderRadius: 10,
                overflow: "hidden",
                marginTop: 10,
              }}
            >
              <GoogleMapComp />
            </Col>
          </Row>
        </Card>
      </Container>
    </Container>
  );
}

export default Contact;
