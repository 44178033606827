import React from "react";
import { Row, Col } from "react-bootstrap";
import { MdLocationOn, MdCall, MdEmail } from "react-icons/md";

function ContactInformation() {
  return (
    <div>
      <h1 className="project-heading" style={{ padding: 10 }}>
        Contact <strong className="purple">Us</strong>
      </h1>
      <Col className="contact-icons">
        <Row className="contact-row">
          <MdLocationOn size={42} />
        </Row>
        <Row className="contact-row">
          <h1 className="project-counter-title">
            <a
              target="_blank"
              href="http://maps.google.com/?q=2945 Clover St. Pittsford, NY 14534"
            >
              2945 Clover St. Pittsford, NY 14534
            </a>
          </h1>
        </Row>
      </Col>

      <Col className="contact-icons">
        <Row className="contact-row">
          <MdEmail size={42} />
        </Row>
        <Row className="contact-row">
          <h1 className="project-counter-title">
            <a href="mailto:chas.psaapp@gmail.com">chas.psaapp@gmail.com</a>
          </h1>
        </Row>
      </Col>
    </div>
  );
}

export default ContactInformation;
