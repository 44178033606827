import about_us from './about_us.png'
import img_0016 from './img_0016.PNG'
import img_0017 from './img_0017.PNG'
import img_0018 from './img_0018.PNG'
import img_0019 from './img_0019.PNG'
import award_plaque from './award_plaque.jpg'
import nishant from './nishant.png'

export const SalonData = {
    about_us: about_us,
    img_0016: img_0016,
    img_0017: img_0017,
    img_0018: img_0018,
    img_0019: img_0019,
    award_plaque: award_plaque,
    nishant: nishant,
}