import React from "react";
import { Col, Row } from "react-bootstrap";

import { SalonData } from "../../../Assets/salon";
import SocialInfo from "../SocialInfo";
import NishantInfo from "./NishantInfo";

function Nishant() {
  return (
    <Row style={{ justifyContent: "center", padding: "10px" }}>
      <Col
        md={7}
        style={{
          justifyContent: "center",
          paddingTop: "30px",
          paddingBottom: "50px",
        }}
      >
        <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
          Our <strong className="purple">Developer</strong>
        </h1>
        <NishantInfo />
      </Col>
      <Col
        md={5}
        style={{ paddingTop: "120px", paddingBottom: "50px" }}
        className="about-img"
      >
        <img src={SalonData.nishant} alt="about" className="about-us" />
        <SocialInfo link={{l:'https://www.linkedin.com/in/nishant-som-855a7b40/'}}/>
      </Col>
    </Row>
  );
}

export default Nishant;
