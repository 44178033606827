import React from "react";

import GoogleMapReact from "google-map-react";
import {
  FaMapMarkerAlt,
} from "react-icons/fa";

const AnyReactComponent = () => <FaMapMarkerAlt color="red" size={32}/>;

function GoogleMapComp() {
  const style = {
    width: "100%",
    height: "100%",
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBB3byD7fbs78G6i23ODC53xS0TFzLXhD8" }}
        defaultCenter={{
          lat: 43.080510,
          lng: -77.546730,
        }}
        defaultZoom={11}
      >
        <AnyReactComponent lat={43.080510} lng={-77.546730} text="My Markerfdgdfgfdgdf'jgjdfgjdfjgdfjgjdflkjglfdjgjlj" />
      </GoogleMapReact>
    </div>
  );
}

export default GoogleMapComp;