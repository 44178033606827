import React from "react";

import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { SalonData } from "../../Assets/salon";

function CarouselComponent() {

  return (
    <div>
      <div className="container-fluid" >
        <div className="row">
          <div className="col-12">
            <Carousel nextIcon ={false} prevIcon={false} >
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SalonData.img_0016}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3></h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SalonData.img_0017}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3></h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SalonData.img_0018}
                  alt="Third slide"
                />
                <Carousel.Caption>
                  <h3></h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SalonData.img_0019}
                  alt="Third slide"
                />
                <Carousel.Caption>
                  <h3></h3>
                  <p></p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarouselComponent;