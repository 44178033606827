import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  AiFillFacebook,
  AiFillTwitterSquare,
  AiFillLinkedin,
} from "react-icons/ai";

function SocialInfo({ link }) {
  return (
    <Row className="social">
      {link.f && (
        <Col>
          <li className="social-icons">
            <a
              href={link.f}
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillFacebook size={34} className="social-icon" />
            </a>
          </li>
        </Col>
      )}
      {link.l && (
        <Col>
          <li className="social-icons">
            <a
              href={link && link.l}
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillLinkedin size={34} className="social-icon" />
            </a>
          </li>
        </Col>
      )}
      {link.t && (
        <Col>
          <li className="social-icons">
            <a
              href={link && link.t}
              style={{ color: "white" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillTwitterSquare size={34} className="social-icon" />
            </a>
          </li>
        </Col>
      )}
    </Row>
  );
}

export default SocialInfo;
