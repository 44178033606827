import React from "react";
import Card from "react-bootstrap/Card";

function CompanyInfo() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Software company for stylists, salon owners and educators.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default CompanyInfo;
