import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";
import AboutCard from "./AboutCard";

import PDF from "../../Assets/projects/PSA_for_iPhone_Manual.pdf";

function ProjectCards(props) {
  return (
    <Card style={{backgroundColor: 'transparent', borderBlockColor: 'transparent'}}>
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <AboutCard />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <Button variant="primary" target="_blank" onClick={() => {window.open(PDF)}}>
          <BiLinkExternal /> &nbsp;
            View Details
        </Button>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
