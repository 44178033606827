import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../components/Particle";

import { Company, Chas, Nishant } from "../components/About";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Company />
        <Chas />
        <Nishant />
      </Container>
    </Container>
  );
}

export default About;
