import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import logo_bg from "../../Assets/projects/logo_bg.png";
import scan_download from "../../Assets/projects/scan_download.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our Product <strong className="purple">Details </strong>
        </h1>
        <div className="project-card-view">
          <Row style={{ justifyContent: "center" }}>
            <Col xs={10} md={6}>
              <ProjectCard
                imgPath={logo_bg}
                isBlog={false}
                title="Description"
                description="PSA+ is the most complete salon management app for the individual stylist available. You can switch from your iPhone to your iPad for your convenience. Once you buy Salon4one you own it with no monthly fees. We, at Salontechnologies, are hair stylists too and we just want to make life better for you."
              />
            </Col>
            <Col xs={8} md={6}>
              <h1 className="scaner-heading">
                Get <strong className="purple">PSA+ </strong>
              </h1>
              <p style={{ color: "white" }}>To download PSA+ iOS app please scan the QR codes.</p>
              <Image
                className="download-scaner"
                src={scan_download}
                alt="Third slide"
                onClick={() => {window.open('https://apps.apple.com/us/app/personal-salon-assistant-plus/id814255572')}}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </Container>
  );
}

export default Projects;
