import React from "react";
import { Row, Col } from "react-bootstrap";
import CountUp from "react-countup";

import { RiEmotionHappyLine, RiDownloadCloud2Line, RiStarLine } from "react-icons/ri";

const Counter = ({downloads, ratings, happy_clients}) => {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <Row className="contact-row">
          <RiDownloadCloud2Line size={62}/>
        </Row>
        <Row className="contact-row">
          <CountUp className="project-counter" duration={3} end={downloads} suffix="+" />
        </Row>
        <Row className="contact-row">
          <h1 className="project-counter-title">Downloads</h1>
        </Row>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <Row className="contact-row">
          <RiStarLine size={62}/>
        </Row>
        <Row className="contact-row">
          <h1 className="project-counter">{ratings}</h1>
        </Row>
        <Row className="contact-row">
          <h1 className="project-counter-title">Ratings</h1>
        </Row>
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <Row className="contact-row">
          <RiEmotionHappyLine size={62}/>
        </Row>
        <Row className="contact-row">
          <CountUp className="project-counter" duration={3} end={happy_clients} suffix="+" />
        </Row>
        <Row className="contact-row">
          <h1 className="project-counter-title">Happy Clients</h1>
        </Row>
      </Col>
    </Row>
  );
};

export default Counter;