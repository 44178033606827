import React from "react";
import { Row, Col } from "react-bootstrap";

import { SalonData } from "../../../Assets/salon";
import CompanyInfo from "./CompanyInfo";

function Company() {
  return (
    <Row style={{ justifyContent: "center", padding: "10px" }}>
      <Col
        md={7}
        style={{
          justifyContent: "center",
          paddingTop: "30px",
          paddingBottom: "50px",
        }}
      >
        <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
          About <strong className="purple">Company</strong>
        </h1>
        <CompanyInfo />
      </Col>
      <Col
        md={5}
        style={{ paddingTop: "120px", paddingBottom: "50px" }}
        className="about-img"
      >
        <img src={SalonData.award_plaque} alt="about" className="about-us" />
      </Col>
    </Row>
  );
}

export default Company;
