import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            #1 <span className="purple">PSA+</span> (and highest user rating) since December 2009
            <br />
            #2 <span className="purple">PSA+</span> is available on the monthly basis auto-renew subscription for <span className="purple">US$9.99</span>.
            <br />
            <br />
            #FEATURES:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Create new contacts.
            </li>
            <li className="about-activity">
              <ImPointRight /> Scheduling
            </li>
            <li className="about-activity">
              <ImPointRight /> Hair Color Formulations
            </li>
            <li className="about-activity">
              <ImPointRight /> Point-of-Sale
            </li>
            <li className="about-activity">
              <ImPointRight /> Products and Services
            </li>
            <li className="about-activity">
              <ImPointRight /> Reporting
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
