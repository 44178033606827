import React from "react";
import { Col, Row } from "react-bootstrap";

import { SalonData } from "../../../Assets/salon";
import SocialInfo from "../SocialInfo";
import ChasInfo from "./ChasInfo";

function Chas() {
  return (
    <Row style={{ justifyContent: "center", padding: "10px" }}>
      <Col
        md={5}
        style={{ paddingTop: "120px", paddingBottom: "50px" }}
        className="about-img"
      >
        <img src={SalonData.about_us} alt="about" className="about-us" />
        <SocialInfo link={{f:'https://www.facebook.com/ChasInzinna', l:'https://www.linkedin.com/in/chas-inzinna-bb095917', t:'https://twitter.com/chasinzinna'}}/>
      </Col>
      <Col
        md={7}
        style={{
          justifyContent: "center",
          paddingTop: "30px",
          paddingBottom: "50px",
        }}
      >
        <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
          Know Who <strong className="purple">I'M</strong>
        </h1>
        <ChasInfo />
      </Col>
    </Row>
  );
}

export default Chas;
